import React, { useState } from "react";
import { useEffect } from "react";
import Lottie from "react-lottie";
import checkAnimation from "../../animations/check.json";
import toggleAnimation from "../../animations/toggle.json";
import downloadAnimation from "../../animations/download.json";
import customizeAnimation from "../../animations/customize.json";
import dragAnimation from "../../animations/dragRight.json";
import folderAnimation from "../../animations/folder.json";
import expandAnimation from "../../animations/expand.json";
import "../../styles/animate.css";
import "../../styles/screenie.scss";

import screenie_usage_static from "../../images/screenie_usage_static.jpg";
import screenie_usage_1 from "../../images/screenie_usage_1.mp4";
import screenie_drag_video from "../../images/screenie_drag_video.mp4";
import screenie_drag_video_static from "../../images/screenie_drag_video_static.jpg";

import screenie_icon_webp from "../../images/screenie_icon.webp";
import screenie_icon_png from "../../images/screenie_icon.png";
import {
  Container,
  Button,
  Jumbotron,
  Image,
  Card,
  Badge,
  Carousel
} from "react-bootstrap";

import screenie_search_1 from "../../images/screenie_search_1.jpg";
import screenie_search_2 from "../../images/screenie_search_2.jpg";
import screenie_search_3 from "../../images/screenie_search_3.jpg";
import screenie_search_4 from "../../images/screenie_search_4.jpg";
import screenie_search_5 from "../../images/screenie_search_5.jpg";
import favicon from "../../images/screenieicon_16x16@2x.png";

import mac_app_store_download from "../../images/mac_app_store_download.svg";
import gumroad from "../../images/gumroad.png";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

function ScreeniePage() {
  const [firstAnimationDone, setFirstAnimationDone] = useState(false);
  const [secondAnimationDone, setSecondAnimationDone] = useState(false);
  const [togglePaused, setTogglePaused] = useState(true);
  const [downloadPaused, setDownloadPaused] = useState(true);
  const [customizePaused, setCustomizedPaused] = useState(true);
  const [dragPaused, setDragPaused] = useState(true);
  const [folderPaused, setFolderPaused] = useState(true);
  const [expandPaused, setExpandPaused] = useState(true);

  const firstCheckOptions = {
    loop: false,
    autoplay: true,
    animationData: checkAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const secondCheckOptions = {
    loop: false,
    autoplay: false,
    animationData: checkAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const thirdCheckOptions = {
    loop: false,
    autoplay: false,
    animationData: checkAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Layout pageInfo={{ pageName: "screenie" }}>
      <SEO
        title="Screenie for Mac - Drag screenshots from your menubar, search text inside your images"
        author="@getscreenie"
        favicon32={favicon}
        shareImage="https://www.thnkdev.com/Screenie/img/shareImage2.jpeg"
        keywords={[
          `snapndrag`,
          `dreamshot`,
          `screenshot shortcut`,
          `finalshot`,
          `monosnap`,
          `lightshot`,
          `snappy`,
          `screenfloat`,
          `snapndrag free`,
          `dreamshot free`,
          `finalshot free`,
          `monosnap free`,
          `lightshot free`,
          `snappy free`,
          `screenfloat free`,
          `snapndrag free download`,
          `dreamshot free download`,
          `finalshot free download`,
          `monosnap free download`,
          `lightshot free download`,
          `snappy free download`,
          `screenfloat free download`,
          `screenshot menu`,
          `how to take screenshots`,
          `how do i take a screenshot`,
          `screenshot mac`,
          `how can i take a screenshot on a mac`,
          `search screenshots`,
          `find a screenshot`,
          `best screenshot app`,
          `best screen app`,
          `best screenshot app for mac`,
          `popular screenshot app`,
          `macos screenshot app`,
          `how to take a screenshot with touchbar`,
          `screenshot menubar`,
          `screencast`,
          `screenshot capture software`,
          `osx screenshot`,
          `how to do screenshot`,
          `screenshot utility`,
          `search text in your screenshot`,
          `search text in your images`,
          `search images`,
          `search your images mac`,
          `how do i search text in my screenshot`,
          `how can i search text in my screenshot`,
          `how can i search text in my images`,
          `can i search text in images on mac`,
          `how do i search text in images`,
          `screenshot search text`,
          `drag screenshot`,
          `drag screenshots from menubar mac`,
          `how do i drag a screenshot from the menubar`,
          `how do i change where mac saves screenshots`,
          `how to change where mac saves screenshots`,
          `change where macos saves screenshots`,
          `where does mac save screenshots`,
          `change where mac saves screenshots`,
          `can i change where mac saves screenshots`,
          `can i change where my computer saves screenshots`,
          `free screenshot app`,
          `mac free screenshot app`,
          `macos catalina free screenshot app`,
          `best productivity app for screenshots`,
          `screnie`,
          `screenie trial`,
          `how can i edit a screenshot mac`,
          `save mac screenshots`,
          `save screenshots to a different folder`,
          `How to Disable Screenshot Thumbnail Previews on Mac`,
          `Disable Screenshot Thumbnail mac`,
          `Disable Screenshot Thumbnail free`,
          `keyboard shortcut for mac screenshots`,
          `How to Turn Off macOS Mojave Screenshot Preview Thumbnails`,
          `How to Turn Off macOS catalina Screenshot Preview Thumbnails`,
          `How to Turn Off macOS Screenshot Preview Thumbnails`,
          `best screenshot app macos catalina`,
          `best screenshot app macos mojave`,
          `best free screenshot app mac`,
          `best free screenshot app mac free`,
          `best free screenshot app macos free `,
          `best screenshot app for mac`,
          `best screenshot app for mac 2019`,
          `best screenshot app for mac 2020`,
          `best screenshot app for mac 2021`,
          `screenshot application mac`,
          `mac screenshot app shortcut`,
          `screenshot mac apple`,
          `screenshot mac app free`,
          `Snapshot Tool for Mac`,
          `Snappy Snapshot Tool for Mac`,
          `Screen Capture Tool for Mac`,
          `Screenshot Tool for Mac`,
          `How to take a screenshot on your Mac`,
          `best screenshot app for macos`,
          `snagit`,
          `monosnap`,
          `snagit free`,
          `monosnap free`,
          `get screenie for free`,
          `screenie free`,
          `screenie free download`,
          `best screenshot app for mac`
        ]}
      />
      <Jumbotron className="panel1">
        <div className="screeniePanelWrapper">
          <div className="screenieDownloadWrapper">
            <div className="screenieDownloadImageWrapper">
              <video
                width="100%"
                className="rounded"
                poster={screenie_usage_static}
                autoPlay
                loop
                muted
                playsInline
              >
                <source src={screenie_usage_1} type="video/mp4" />
              </video>
            </div>
            <div className="screenieDownloadHero">
              <div className="screenieTextRightWrapper">
                <div className="screenieTextLogoCombo">
                  <div className="screenieIconWrapper">
                    <picture>
                      <source srcset={screenie_icon_webp} type="image/webp" />
                      <source srcset={screenie_icon_png} type="image/png" />
                      <img width="120" src={screenie_icon_png} />
                    </picture>
                  </div>
                  <div className="screenieTextRight">
                    <div className="screenie2">Screenie&nbsp;2</div>
                    <div className="screenieTextRightTagline">
                      Your favorite screenshot manager reimagined
                    </div>
                  </div>
                </div>
                <div className="screenieTextRightSubtext">
                  <div className="checkboxWrapper">
                    <div className="mainCheck">
                      <div className="lottiecheck">
                        <Lottie
                          options={firstCheckOptions}
                          height={40}
                          width={40}
                          isStopped={false}
                          isPaused={false}
                          eventListeners={[
                            {
                              eventName: "complete",
                              callback: () => setFirstAnimationDone(true)
                            }
                          ]}
                        />
                      </div>
                      <span className="checkText">
                        Drag screenshots any time from your menubar
                      </span>
                    </div>
                    <div className="mainCheck">
                      <div className="lottiecheck">
                        <Lottie
                          options={secondCheckOptions}
                          height={40}
                          width={40}
                          isPaused={!firstAnimationDone}
                          eventListeners={[
                            {
                              eventName: "complete",
                              callback: () => setSecondAnimationDone(true)
                            }
                          ]}
                        />
                      </div>
                      <span className="checkText checkText2">
                        Preview any images from the folder of your choice
                      </span>
                    </div>
                    <div className="mainCheck">
                      <div className="lottiecheck">
                        <Lottie
                          options={thirdCheckOptions}
                          height={40}
                          width={40}
                          isPaused={!secondAnimationDone}
                        />
                      </div>
                      <span className="checkText checkText3">
                        Search the text inside your images using cutting edge
                        optical character recognition technology
                      </span>
                    </div>
                  </div>

                  <div className="screenieDownloadPrimaryBtn">
                    <a
                      href="https://apps.apple.com/us/app/screenie/id965442961?mt=12"
                      target="_blank"
                    >
                      <Image
                        src={mac_app_store_download}
                        className="macAppStoreDownload"
                      />
                    </a>
                    <div className="downloadButtonRequirementWrapper">
                      <Badge variant="secondary">
                        macOS Catalina or later required
                      </Badge>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Jumbotron>

      <Jumbotron fluid>
        <Container className="panel2OuterWrapper">
          <div className="panel2TextWrapper">
            That's right, search the text inside your images.
          </div>

          <Carousel className="carouselSearchResults">
            <Carousel.Item>
              <Image
                src={screenie_search_1}
                thumbnail
                className="d-block w-100"
              />
            </Carousel.Item>
            <Carousel.Item>
              <Image
                src={screenie_search_2}
                thumbnail
                className="d-block w-100"
              />
            </Carousel.Item>
            <Carousel.Item>
              <Image
                src={screenie_search_3}
                thumbnail
                className="d-block w-100"
              />
            </Carousel.Item>
            <Carousel.Item>
              <Image
                src={screenie_search_4}
                thumbnail
                className="d-block w-100"
              />
            </Carousel.Item>
            <Carousel.Item>
              <Image
                src={screenie_search_5}
                thumbnail
                className="d-block w-100"
              />
            </Carousel.Item>
          </Carousel>
        </Container>
      </Jumbotron>

      <Jumbotron fluid>
        <Container className="panel2OuterWrapper">
          <div className="panel2TextWrapper">
            Drag screenshots from your menubar.
          </div>
          <div className="screenieDownloadImageWrapper">
            <video
              width="100%"
              className="rounded"
              poster={screenie_drag_video_static}
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={screenie_drag_video} type="video/mp4" />
            </video>
          </div>
        </Container>
      </Jumbotron>

      <Jumbotron fluid>
        <Container>
          <div className="panel2TextWrapper">
            So, what else can Screenie do?
          </div>

          <div className="featureRow">
            <Card>
              <Card.Body
                onMouseEnter={() => setFolderPaused(false)}
                onMouseLeave={() => setFolderPaused(true)}
              >
                <Lottie
                  width="40px"
                  height="40px"
                  isPaused={folderPaused}
                  options={{
                    animationData: folderAnimation,
                    autoplay: false
                  }}
                />

                <Card.Text className="featureText">
                  Change the location of where macOS saves your screenshots (only on Gumroad version)
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body
                onMouseEnter={() => setCustomizedPaused(false)}
                onMouseLeave={() => setCustomizedPaused(true)}
              >
                <Lottie
                  width="40px"
                  height="40px"
                  isPaused={customizePaused}
                  options={{
                    animationData: customizeAnimation,
                    autoplay: false
                  }}
                />

                <Card.Text className="featureText">
                  Tons of customization. Change the theme, number of columns and
                  Screenie image panel height.
                </Card.Text>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body
                onMouseEnter={() => setDragPaused(false)}
                onMouseLeave={() => setDragPaused(true)}
              >
                <Lottie
                  width="40px"
                  height="40px"
                  isPaused={dragPaused}
                  options={{
                    animationData: dragAnimation,
                    autoplay: false
                  }}
                />

                <Card.Text className="featureText">
                  Take a screenshot on your Mac. Screenie will let you drag the
                  screenshot immediately right from the menubar icon.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="featureRow">
            <Card>
              <Card.Body
                onMouseEnter={() => setExpandPaused(false)}
                onMouseLeave={() => setExpandPaused(true)}
              >
                <Lottie
                  width="40px"
                  height="40px"
                  isPaused={expandPaused}
                  options={{
                    animationData: expandAnimation,
                    autoplay: false
                  }}
                />

                <Card.Text className="featureText">
                  Bring up the Screenie image panel from a global keyboard
                  shortcut
                </Card.Text>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body
                onMouseEnter={() => setTogglePaused(false)}
                onMouseLeave={() => setTogglePaused(true)}
              >
                <Lottie
                  width="40px"
                  height="40px"
                  isPaused={togglePaused}
                  options={{
                    animationData: toggleAnimation,
                    autoplay: false
                  }}
                />

                <Card.Text className="featureText">
                  Toggle the default macOS behavior showing the screenshot in
                  the bottom right corner
                </Card.Text>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body
                onMouseEnter={() => setDownloadPaused(false)}
                onMouseLeave={() => setDownloadPaused(true)}
              >
                <Lottie
                  width="40px"
                  height="40px"
                  isPaused={downloadPaused}
                  options={{
                    animationData: downloadAnimation,
                    autoplay: false
                  }}
                />

                <Card.Text className="featureText">
                  Free updates for life. No in app purchases, no limitations.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </Jumbotron>
      <Jumbotron className="panel3">
        <div className="screeniePanelWrapper">
          <div className="screenieDownloadWrapper">
            <div className="screenieDownloadHero">
              <div className="screenieTextRightWrapper">
                <div className="screenieTextLogoCombo">
                  <div className="screenieIconWrapper">
                    <picture>
                      <source srcset={screenie_icon_webp} type="image/webp" />
                      <source srcset={screenie_icon_png} type="image/png" />
                      <img width="120" src={screenie_icon_png} />
                    </picture>
                  </div>
                  <div className="screenieTextRight">
                    <div className="screenie2">Screenie&nbsp;2</div>
                    <div className="screenieTextRightTagline">
                      Your favorite screenshot manager reimagined
                    </div>
                  </div>
                </div>

                <div className="screenieDownloadPrimaryBtn">
                  <a
                    href="https://gum.co/screenie2"
                    target="_blank"
                    data-gumroad-single-product="true"
                  >
                    <Button
                      variant="primary"
                      size="lg"
                      className="primaryDownloadButton"
                    >
                      Download <Image
                        src={gumroad}
                        className="gumroad"
                      />
                    </Button>
                  </a>

                  <a
                    href="https://www.producthunt.com/posts/screenie-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-screenie-2"
                    target="_blank"
                  >
                    <Image
                      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=180984&theme=dark"
                      className="productHuntBtn"
                    />
                  </a>

                  <div className="downloadAltScreenie1">
                    <a
                      href="https://gum.co/screenie"
                      target="_blank"
                      data-gumroad-single-product="true"
                    >
                      <Button variant="link" size="sm">
                        Don't have macOS Catalina yet? Download Screenie v1 free
                        here!
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Jumbotron>
    </Layout>
  );
}

export default ScreeniePage;
